// Loads the json literal (resource strings) file dynamically

/* Ideally, we could have used expressions and dynamic imports to get this.
 * However, it was not working and after spending more than half a day, I had to give up as
 * we have deadlines to meet.
 * In future, we can update this to use dynamic imports with expressions
 */

import { englishConstants } from "./en/constants";
import { arabicConstants } from "./ar/constants";

const getEnglishLiterals = (routeName) => {
  switch (routeName) {
    case "billing":
      return import(/* webpackMode: "eager" */ "./en/billing.json");
    case "common":
      return import(/* webpackMode: "eager" */ "./en/common.json");
    case "unauthRoot":
      return import(/* webpackMode: "eager" */ "./en/unauthRoot.json");
    case "signIn":
      return import(/* webpackMode: "eager" */ "./en/signIn.json");
    case "forgotPassword":
      return import(/* webpackMode: "eager" */ "./en/forgotPassword.json");
    case "flights":
      return import(/* webpackMode: "eager" */ "./en/flights.json");
    case "signUp":
      return import(/* webpackMode: "eager" */ "./en/signUp.json");
    case "setPassword":
      return import(/* webpackMode: "eager" */ "./en/setPassword.json");
    case "travellers":
      return import(/* webpackMode: "eager" */ "./en/travellers.json");
    case "tags":
      return import(/* webpackMode: "eager" */ "./en/tags.json");
    case "signInIntro":
      return import(/* webpackMode: "eager" */ "./en/signInIntro.json");
    case "myaccount":
      return import(/* webpackMode: "eager" */ "./en/myaccount.json");
    case "policies":
      return import(/* webpackMode: "eager" */ "./en/policies.json");
    case "trips":
      return import(/* webpackMode: "eager" */ "./en/trips.json");
    case "components":
      return import(/* webpackMode: "eager" */ "./en/components.json");
    case "approvals":
      return import(/* webpackMode: "eager" */ "./en/approvals.json");
    case "suspendedAccount":
      return import(/* webpackMode: "eager" */ "./en/suspendedAccount.json");
    case "hotels":
      return import(/* webpackMode: "eager" */ "./en/hotels.json");
    case "requestForm":
      return import(/* webpackMode: "eager" */ "./en/requestForm.json");
    case "expenseManagement":
      return import(/* webpackMode: "eager" */ "./en/expenseManagement.json");
    case "seatMap":
      return import(/* webpackMode: "eager" */ "./en/seatMap.json");
    case "quotation":
      return import(/* webpackMode: "eager" */ "./en/quotation.json");
    default:
      return null;
  }
};

const getArabicLiterals = (routeName) => {
  switch (routeName) {
    case "common":
      return import(/* webpackMode: "eager" */ "./ar/common.json");
    case "unauthRoot":
      return import(/* webpackMode: "eager" */ "./ar/unauthRoot.json");
    case "signIn":
      return import(/* webpackMode: "eager" */ "./ar/signIn.json");
    case "forgotPassword":
      return import(/* webpackMode: "eager" */ "./ar/forgotPassword.json");
    case "flights":
      return import(/* webpackMode: "eager" */ "./ar/flights.json");
    case "signUp":
      return import(/* webpackMode: "eager" */ "./ar/signUp.json");
    case "setPassword":
      return import(/* webpackMode: "eager" */ "./ar/setPassword.json");
    case "travellers":
      return import(/* webpackMode: "eager" */ "./ar/travellers.json");
    case "tags":
      return import(/* webpackMode: "eager" */ "./ar/tags.json");
    case "signInIntro":
      return import(/* webpackMode: "eager" */ "./ar/signInIntro.json");
    case "myaccount":
      return import(/* webpackMode: "eager" */ "./ar/myaccount.json");
    case "policies":
      return import(/* webpackMode: "eager" */ "./ar/policies.json");
    case "trips":
      return import(/* webpackMode: "eager" */ "./ar/trips.json");
    case "components":
      return import(/* webpackMode: "eager" */ "./ar/components.json");
    case "approvals":
      return import(/* webpackMode: "eager" */ "./ar/approvals.json");
    case "suspendedAccount":
      return import(/* webpackMode: "eager" */ "./ar/suspendedAccount.json");
    case "hotels":
      return import(/* webpackMode: "eager" */ "./ar/hotels.json");
    case "requestForm":
      return import(/* webpackMode: "eager" */ "./ar/requestForm.json");
    case "expenseManagement":
      return import(/* webpackMode: "eager" */ "./ar/expenseManagement.json");
    case "seatMap":
      return import(/* webpackMode: "eager" */ "./ar/seatMap.json");
    case "quotation":
      return import(/* webpackMode: "eager" */ "./en/quotation.json");
    default:
      return null;
  }
};

export const load = function (lang = "en", routeName) {
  if (lang === "en") {
    return getEnglishLiterals(routeName);
  }
  if (lang === "ar") {
    return getArabicLiterals(routeName);
  }
  return null;
};

export const getConstants = function (lang) {
  switch (lang) {
    case "en":
      return englishConstants;
    case "ar":
      return arabicConstants;
    default:
      return null;
  }
};
